import React from 'react'
import { ToastContainer } from 'react-toastify'
import { ApolloProvider } from '@apollo/client'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { CartProvider } from 'use-shopping-cart'

import '../graphql/fragments'

import { AppProvider } from '../contexts/app-context'
import { NotificationProvider } from '../contexts/notification-context'
import client from '../gatsby-plugin-apollo/client.js'

if (process.env.NODE_ENV !== 'production') {
  loadDevMessages()
  loadErrorMessages()
}

const App = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <ToastContainer position='bottom-center' theme='colored' />
      <NotificationProvider>
        <CartProvider
          cartMode='checkout-session'
          language='fr'
          stripe={process.env.GATSBY_STRIPE_PUBLIC_KEY}
          currency='EUR'
        >
          <AppProvider>{children}</AppProvider>
        </CartProvider>
      </NotificationProvider>
    </ApolloProvider>
  )
}

export default App
