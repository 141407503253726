import { gql } from '@apollo/client'

import {
  CREATION_FRAGMENT_SHORT,
  CRITIC_FRAGMENT_SHORT,
  CULT_FRAGMENT_SHORT,
} from './posts'

export const SEARCH_POSTS = gql`
  ${CULT_FRAGMENT_SHORT}
  ${CREATION_FRAGMENT_SHORT}
  ${CRITIC_FRAGMENT_SHORT}
  query searchContent(
    $search: String!
    $orderBy: PostObjectsConnectionOrderbyEnum!
    $order: OrderEnum!
    $contentTypes: [ContentTypeEnum]!
  ) {
    posts: contentNodes(
      where: {
        search: $search
        contentTypes: $contentTypes
        orderby: { field: $orderBy, order: $order }
      }
    ) {
      nodes {
        id
        contentTypeName
        slug
        uri
        ... on Critique {
          ...CriticFragmentShort
        }
        ... on Culte {
          ...CultFragmentShort
        }
        ... on Creation {
          ...CreationFragmentShort
        }
        ... on CallToCreations {
          id
          title
          slug
          callToCreations {
            active
            enddatelabel
          }
        }
        ... on Event {
          slug
          title
          event {
            eventDate
          }
        }
      }
    }
  }
`
