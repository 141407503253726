import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'

import client from '../gatsby-plugin-apollo/client'
import { SEARCH_POSTS } from '../graphql/search'

import { useDebouncedCallback } from './debounce'

export const SEARCH_DELAY = 1000

const useData = (search, variables) => {
  const [debounceValue, setDebouncedValue] = useState(search)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(search)
    }, SEARCH_DELAY)

    return () => {
      clearTimeout(handler)
    }
  }, [search])

  const response = useQuery(SEARCH_POSTS, {
    variables: {
      search: debounceValue,
      ...variables,
    },
    skip: debounceValue.length <= 2,
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (debounceValue.length > 2) {
      response.refetch({ variables: { search: debounceValue, ...variables } })
    }
  }, [debounceValue, variables])

  return response
}

export const useSearch = () => {
  const categories = [
    'CREATIONS',
    'CULTES',
    'CRITIQUES',
    'APPEL_A_CREATIONS',
    'EVENEMENTS',
  ]
  const [search, setSearch] = useState('')
  const [result, setResult] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [orderBy, setOrderBy] = useState('DATE')
  const [order, setOrder] = useState('DESC')

  const [_search, params] = useMemo(
    () => [
      search,
      {
        search,
        contentTypes:
          selectedCategory === null ? categories : [selectedCategory],
        orderBy,
        order,
      },
    ],
    [search, selectedCategory, order, orderBy]
  )

  const { data, loading } = useData(_search, params)

  const handleClickOrderBy = (_orderBy) => {
    if (_orderBy === orderBy) {
      setOrder((order) => (order === 'DESC' ? 'ASC' : 'DESC'))
      return
    }
    setOrderBy(_orderBy)
    setOrder('DESC')
  }

  useEffect(() => {
    setResult(data?.posts?.nodes || [])
  }, [data, loading])

  return {
    loading,
    search,
    setSearch,
    result,
    selectedCategory,
    setSelectedCategory,
    orderBy,
    setOrderBy: handleClickOrderBy,
    order,
  }
}
