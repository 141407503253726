import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  fromPromise,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import fetch from 'isomorphic-fetch'
import { uniqBy } from 'lodash'

import { REFRESH_TOKEN } from '../graphql/auth'
import { getWin } from '../utils/window'

const wpApi = process.env.GATSBY_WP_API_URL

const httpLink = createHttpLink({ uri: `${wpApi}/graphql`, fetch })

const simpleClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
})

const refreshAccessToken = async (refreshToken) => {
  console.info('request a new token with saved refresh token')
  const response = await simpleClient.mutate({
    mutation: REFRESH_TOKEN,
    variables: { refreshToken },
  })
  const { authToken } = response.data.refreshToken
  return authToken
}

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

// @TODO : maybe harden security
// https://hasura.io/blog/best-practices-of-using-jwt-with-graphql/
// TLDR : don't use localStorage but a mix of local, session & http only cookies
// and need server stuff for fingerprinting tokens
const updateAuthTokenLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    // may be expired token
    const refreshToken = localStorage.getItem('refreshToken')
    if (!refreshToken) {
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('token')
      return
    }
    if (networkError?.statusCode === 403) {
      console.info('403 graphql error, bad auth, wipe tokens')
      return fromPromise(
        refreshAccessToken(refreshToken).catch((err) => {
          console.info('could not refresh token', err.message)
          localStorage.removeItem('refreshToken')
          localStorage.removeItem('token')
          getWin().location.assign('/')
        })
      )
        .filter(Boolean)
        .flatMap((token) => {
          console.info('save new token')
          localStorage.setItem('token', token)
          const oldHeaders = operation.getContext().headers
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${token}`,
            },
          })

          return forward(operation)
        })
    }
  }
)

const updateRefreshTokenLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext()
    const { headers } = context.response
    if (headers) {
      const refreshToken = headers.get('x-wpgraphql-login-refresh-token')
      const authToken = headers.get('x-wpgraphql-login-token')
      const oldRefreshToken = localStorage.getItem('refreshToken')
      const oldAuthToken = localStorage.getItem('token')
      if (authToken && authToken !== oldAuthToken) {
        console.info('save new auth token')
        localStorage.setItem('token', authToken)
      }
      if (refreshToken && refreshToken !== oldRefreshToken) {
        console.info('save new refresh token')
        localStorage.setItem('refreshToken', refreshToken)
      }
    }
    return response
  })
})

const mergeFunction = (existing = { pageInfo: {}, nodes: [] }, incoming) => {
  // console.info(existing?.nodes, incoming?.nodes)
  return {
    pageInfo: incoming.pageInfo,
    nodes: uniqBy([...existing.nodes, ...incoming.nodes], '__ref').filter(
      (n) => !!n.__ref
    ),
  }
}

const client = new ApolloClient({
  link: from([updateAuthTokenLink, updateRefreshTokenLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          comments: {
            keyArgs: false,
            merge: mergeFunction,
          },
          contentNodes: {
            keyArgs: false,
            merge: mergeFunction,
          },
          creations: {
            keyArgs: false,
            merge: mergeFunction,
          },
          cultes: {
            keyArgs: false,
            merge: mergeFunction,
          },
          critiques: {
            keyArgs: false,
            merge: mergeFunction,
          },
        },
      },
    },
  }),
})

export default client
